import classNames from "classnames";

import css from "./match_accuracy.module.scss";

type MatchAccuracyBadgeProps = {
  value: number;
  onlyRelated?: boolean;
  onlyIndirect?: boolean;
  style?: React.CSSProperties;
  icon?: JSX.Element;
};

export const MatchAccuracyBadge = ({
  value,
  onlyRelated,
  onlyIndirect,
  style,
  icon
}: MatchAccuracyBadgeProps): JSX.Element => {
  const className = getClassName(value, onlyRelated, onlyIndirect);

  const percentage = Number(parseFloat(String(value * 100)).toFixed(1));

  return (
    <div className={classNames(css.root, className)} style={style}>
      <div className={css.badge}>
        {icon}
        <div className={css.num} style={{ margin: 0 }}>
          {percentage < 5 ? "< 5" : percentage}
          <span>%</span>
        </div>
      </div>
    </div>
  );
};

const getClassName = (
  value: number,
  onlyRelated: boolean | undefined,
  onlyIndirect: boolean | undefined
): string => {
  if (onlyRelated || onlyIndirect) {
    return css.related;
  } else if (value <= 0.6) {
    return css.fair;
  } else if (value <= 0.9) {
    return css.good;
  } else if (value < 1.0) {
    return css.great;
  } else {
    return css.great;
  }
};
