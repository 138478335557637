import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { CustomType } from "PFTypes";
import { useCallback } from "react";

import { getPermittedTypes, loadForSections, PermissionObject } from "./custom_type";

export const useCustomTypes = () => {
  const { data: currentProfile } = useCurrentProfile();
  const { custom_types: customTypes } = currentProfile;

  const getCustomTypeByName = useCallback(
    (name: CustomType["name"]) => customTypes.find((customType) => name === customType.name),
    [customTypes]
  );

  return {
    customTypes,
    loadForSections: (sections: string | string[]) => loadForSections(sections, customTypes),
    getPermittedTypes: (permitionsObject: PermissionObject) =>
      getPermittedTypes(customTypes, permitionsObject),
    getCustomTypeByName
  };
};
