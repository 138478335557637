import classNames from "classnames";
import { usePastDaysLimit } from "PFApp/hooks";
import { letFilterRestore } from "PFApp/use_filtered_collection";
import { AvailabilityInput } from "PFComponents/availability";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Template } from "PFTypes";
import PropTypes from "prop-types";

import css from "./availability_filter.module.scss";
import { useAvailabilityDefaults } from "./hooks/use_availability_defaults";
import { useAvailabilityState } from "./hooks/use_availability_state";
import { AvailabilityLimitWrapper } from "./parts/availability_limit_wrapper";

const AvailabilityFilterItem = ({ filter, handleChange, scope = 0, templateKey = "task" }) => {
  const { utc, formatISODate } = useDateFormatter();
  const { data: currentAccount } = useCurrentAccount();
  const letRestore = letFilterRestore(filter);

  const pastDaysLimit = usePastDaysLimit();

  const { defaultAvailability, allowedModes } = useAvailabilityDefaults(
    filter,
    templateKey as Template["key"]
  );

  const { availability, updateAvailability, restoreAvailability } = useAvailabilityState(
    filter,
    handleChange
  );

  const todayISODate = formatISODate(utc());
  const pastDaysLimitISODate = formatISODate(utc().subtract(pastDaysLimit, "days"));
  const minDate = pastDaysLimit
    ? { start: pastDaysLimitISODate, end: pastDaysLimitISODate }
    : { start: todayISODate, end: todayISODate };

  return (
    <div className={classNames(css.root)}>
      <AvailabilityLimitWrapper scope={scope}>
        <AvailabilityInput
          availability={availability}
          accountAvailability={currentAccount.availability}
          defaultAvailabilityMode={defaultAvailability.mode || undefined}
          defaultAvailabilityThreshold={defaultAvailability.availability_threshold || undefined}
          allowedModes={allowedModes}
          onChange={updateAvailability}
          isFilter
          onRestore={letRestore ? restoreAvailability : undefined}
          minDate={minDate}
        />
      </AvailabilityLimitWrapper>
    </div>
  );
};

AvailabilityFilterItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  scope: PropTypes.number,
  filter: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOf(["availability"]),
    value: PropTypes.oneOfType([
      PropTypes.oneOf([""]), // empty string is valis and consider null
      PropTypes.shape({
        mode: PropTypes.string,
        start: PropTypes.string,
        end: PropTypes.string,
        duration: PropTypes.shape({ mode: PropTypes.string, value: PropTypes.string })
      })
    ]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.oneOf([""]), // empty string is valis and consider null
      PropTypes.shape({
        mode: PropTypes.string,
        start: PropTypes.string,
        end: PropTypes.string,
        duration: PropTypes.shape({ mode: PropTypes.string, value: PropTypes.string })
      })
    ])
  }).isRequired,
  templateKey: PropTypes.string
};

export default AvailabilityFilterItem;
